import { camelCase } from 'lodash-es'

export const strapiGetRouteReference = (uid: string) => {
  let uidString = uid?.includes('.') ? uid?.split('.')?.pop() : uid
  uidString = camelCase(uidString)
  switch (uidString) {
    case 'citiesPage':
    return {
      name: 'cities',
      slug: false
    }

    case 'cityEntry':
      return {
        name: 'cities-slug',
        slug: true
      }

    case 'clustersPage':
      return {
        name: 'clusters',
        slug: false
      }

    case 'clusterEntry':
      return {
        name: 'clusters-slug',
        slug: true
      }

    case 'eventsPage':
      return {
        name: 'events',
        slug: false
      }

    case 'eventEntry':
      return {
        name: 'events-slug',
        slug: true
      }

    case 'focusWeeksPage':
    case 'focusWeekPage':
      return {
        name: 'focus-week',
        slug: false
      }

    case 'focusWeekEntry':
      return {
        name: 'focus-week-slug',
        slug: true
      }

    case 'homePage':
      return {
        name: 'index',
        slug: false
      }

    case 'newsPage':
      return {
        name: 'news',
        slug: false
      }

    case 'newsEntry':
      return {
        name: 'news-slug',
        slug: true
      }

    case 'mapPage':
      return {
        name: 'map',
        slug: false
      }

    case 'pageEntry':
    case 'slug':
      return {
        name: 'slug',
        slug: true
      }

    case 'participantsPage':
      return {
        name: 'participants',
        slug: false
      }

    case 'participantEntry':
      return {
        name: 'participants-slug',
        slug: true
      }

    case 'publicationsPage':
      return {
        name: 'publications',
        slug: false
      }

    case 'publicationsEntry':
      return {
        name: 'publications-slug',
        slug: true
      }

    case 'programmePage':
      return {
        name: 'programme',
        slug: false
      }

    case 'partnersPage':
      return {
        name: 'partners',
        slug: false
      }

    case 'pressPage':
      return {
        name: 'press',
        slug: false
      }

    case 'venuesPage':
      return {
        name: 'venues',
        slug: false
      }

    case 'venueEntry':
      return {
        name: 'venues-slug',
        slug: true
      }

    case 'newsletterPage':
      return {
        name: 'index-newsletter',
        slug: false
      }

    default:
      return {
        name: 'index',
        slug: false
      }
  }
}
